export default class ImageService {

    static url(image, size = null) {
        if(!image)
        {
            return ''
        }
        
        switch (image.service) {
            case 'Cloudinary':
              return size && image.urls[size] ? image.urls[size] : image.file_path
            default:
              return size && image.urls[size] ? `/storage/${image.urls[size]}` : `/storage/${image.file_path}/${image.file_name}`
        }
    }

    static thumbnailStyle(image) {
       return `background:url(${this.url(image, 'thumbnail')}) no-repeat ${image.position};background-size:contain;width:3rem;height:3rem;`
    }
}